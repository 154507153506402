<template>
  <Subview :contentPadding="false" class="main-content settings" customContentPadding="px-0 pb-2 pt-0">
    <DefaultTabs show-arrows showLine :hide-slider="true" v-model="activeTab" slot="subview-content"
      arrowMargins="15px">
      <template v-for="tab in tabs">
        <DefaultTab :key="tab.name" :value="tab.name" :active="tab === tabs[activeTab]" :enabled="tab.enabled">
        </DefaultTab>
      </template>
      <v-tabs-items :class="$vuetify.breakpoint.smAndUp && 'settings-view-tab-content'"
        :style="{maxHeight: $vuetify.breakpoint.smAndUp && `calc(100vh - 176px) !important`}"
        touchless v-model="activeTab">
        <template v-for="tab in tabs">
          <v-tab-item :key="tab.name">
            <v-layout column>
              <component v-if="tab.name == activeTabName" :is="tab.component" @success="handleSuccess"
                @error="handleError" :isLoading.sync="loading"></component>
            </v-layout>
          </v-tab-item>
        </template>
      </v-tabs-items>
    </DefaultTabs>
  </Subview>
</template>

<script>
import Subview from "@/components/Subview";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import ResourcesService from "@/services/resources.service";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  mixins: [ProxyCRUDMixin],
  components: {
    Subview,
    DefaultTabs: () => import("@/components/tabs/DefaultTabs"),
    DefaultTab: () => import("@/components/tabs/DefaultTab"),
    PatientCardSettingsTypeWrapper: () =>
      import("@/components/forms/settings/PatientCardSettingsTypeWrapper"),
    PredefinedFieldsRecordsTypeWrapper: () =>
      import("@/components/forms/settings/PredefinedFieldsRecordsTypeWrapper"),
    BooksyResourcesForm: () =>
      import("@/components/forms/settings/BooksyResourcesForm"),
    P1IntegrationForm: () =>
      import("@/components/forms/settings/P1IntegrationForm"),
  },
  computed: {
    ...mapGetters("ui", ["settingsActiveTab", "accessToMedicalEmployees"]),
    tabs() {
      let tabs = [];
      [
        {
          name: "System P1",
          component: "P1IntegrationForm",
        },
        {
          name: "Pracownicy medyczni",
          component: "BooksyResourcesForm",
          enabled: this.accessToMedicalEmployees,
        },
        {
          name: "Karta pacjenta",
          component: "PatientCardSettingsTypeWrapper",
        },
        {
          name: "Predefiniowane wpisy",
          component: "PredefinedFieldsRecordsTypeWrapper",
        },
      ].forEach((element) => {
        if (element.visible === undefined || element.visible) {
          tabs.push(element);
        }
      });
      return tabs;
    },
    activeTab: {
      get() {
        return this.settingsActiveTab;
      },
      set(value) {
        this.updateSettingsActiveTab(value);
      },
    },
    activeTabName() {
      if (this.tabs[this.activeTab]) {
        return this.tabs[this.activeTab].name;
      } else {
        return "";
      }
    },
    subviewPadded() {
      switch (this.activeTabName) {
        case "Karta pacjenta":
          return false;
        case "Predefiniowane wpisy":
          return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions("ui", [
      "updateSettingsActiveTab",
      "updateAccessToMedicalEmployees",
    ]),
    checkMedicalEmployeesAccess() {
      return ResourcesService.checkMedicalEmployeesAccess()
        .then(() => this.updateAccessToMedicalEmployees(true))
        .catch(() => this.updateAccessToMedicalEmployees(false));
    },
  },
  async created() {
    if (this.$route.query.tab === "p1") {
      this.activeTab = 0;
    }
    await this.checkMedicalEmployeesAccess();
  },
};
</script>

<style lang="scss" scoped>
.settings-view-tab-content {
  background-color: transparent;
  overflow: auto !important;
}
</style>