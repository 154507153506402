import ApiService from "./api.service";

const ResourcesService = {
  async getResources() {
    return await ApiService.commonGETRequest("/booksy/resource/");
  },
  async check() {
    return ApiService.commonGETRequest("/booksy/check/");
  },
  async updateResource(resource) {
    return await ApiService.commonPATCHRequest(
      `/booksy/resource/`,
      resource.id ? { resource_id: resource.id } : {},
      resource
    );
  },
  async checkMedicalEmployeesAccess() {
    return await ApiService.commonGETRequest("/booksy/resource/access/");
  }
};

export default ResourcesService;
